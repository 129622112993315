<template>
  <CModal class="overflow-modal" :visible="visible" backdrop="static">
    <div class="overflow-modal__close-bar">
      <CIcon name="cil-x" @click="$emit('close')" />
    </div>
    <div class="send-contract-modal">
      <div class="overflow-modal__title-wrapper">
        <h4 class="send-contract-modal__title">Send Contract Package</h4>
        <p class="send-contract-modal__text">
          Select a project, then the scope you are ready to send. Assign users,
          review package and merged fields, before sending documents or contracts
        </p>
      </div>

      <div>
        <h5 class="send-contract-modal__category">Choose Project</h5>
        <div class="send-contract-modal__select">
          <Multiselect
            v-model="selectedProject"
            :can-deselect="false"
            placeholder="Search by name or number"
            :searchable="true"
            :options="projectsList"
            label="display_label"
            track-by="label"
            @open="projectsListOpened = true"
            @close="projectsListOpened = false">

            <template #option="{ option }">
              <span>
                {{ option.name }}
              </span>
            </template>

            <template #caret>
              <div class="upload-document-modal__field-search-caret">
                <svg class="dd-caret" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                    fill="currentColor"/>
                </svg>
              </div>
            </template>

          </Multiselect>
          <CIcon name="cil-search" class="send-contract-modal__select-icon" />
        </div>
      </div>

      <div>
        <h5 class="send-contract-modal__category">Choose scope</h5>
        <div class="send-contract-modal__select">
          <Multiselect
            :aria-disabled="scopeListDisabled"
            v-model="selectedScope"
            :disabled="scopeListDisabled"
            placeholder="Search by company or scope name"
            track-by="label"
            label="display_label"
            :can-deselect="false"
            :searchable="true"
            :options="scopesList"
            @open="projectsListOpened = true"
            @close="projectsListOpened = false">

            <template #option="{ option }">
              {{ option.company_name }} / {{ option.name }}
            </template>

            <template #caret>
              <div class="upload-document-modal__field-search-caret">
                <svg class="dd-caret" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                    fill="currentColor"/>
                </svg>
              </div>
            </template>

          </Multiselect>

          <CIcon name="cil-search" class="send-contract-modal__select-icon" />
        </div>
      </div>

      <p class="send-contract-modal__link text-center" @click="onLinkClick">
        Need to  create a new project?
        <router-link :to="{name: 'ProjectNew'}">
          Go to project >
        </router-link>
      </p>

      <div class="app-modal__buttons">
        <CButton
          :disabled="submitDisabled"
          color="primary"
          @click="submit">Review contract</CButton>
      </div>

    </div>
  </CModal>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
  name: "SendContractModal",
  components: { Multiselect },
  props: ['visible'],
  data() {
    return {
      projectsList: [],
      selectedProjectName: '',
      selectedProject: '',
      projectsListOpened: false,

      selectedScopeName: '',
      selectedScope: '',

      testObj: ''
    }
  },
  methods: {
    onLinkClick() {
      if (this.$route.name === 'ProjectNew') {
        this.$emit('close');
      }
    },
    submit() {
      this.$router.push({
        name: 'ProjectScopesTasksAssign',
        params: {
          id: this.selectedProject.id,
          scopeId: this.selectedScope.id
        }
      })
    }
  },
  computed: {
    scopesList() {
      return this.selectedProject?.scopes ? this.selectedProject.scopes.map(scope => ({
        value: scope,
        label: scope.name + scope.company_name,
        display_label: scope.name,
        ...scope
      })) : []
    },
    submitDisabled() {
      return !this.selectedProject || !this.selectedScope
    },
    scopeListDisabled() {
      return this.scopesList.length === 1 || !this.selectedProject
    }
  },
  watch: {
    selectedProject(val) {
      this.selectedScope = ''

      if (!val) return;

      if (this.selectedProject.scopes.length === 1) {
        this.selectedScope = this.selectedProject.scopes[0]
        return;
      }
    },
    selectedScopeName(val) {
      if (!val) return

      this.selectedScope = this.scopesList.find(x => x.company_name + '/' + x.name === val);
    },
    visible(val) {
      if (!val) {
          this.selectedProject = '';
          this.selectedScope = '';
      }
    }
  },
  created() {
    this.$http.projects.sendContractData()
      .then(res => this.projectsList = res.data.data.map(item => ({
        value: item,
        label: item.name + item.internal_number,
        display_label: item.name,
        ...item
      })))
  }
}
</script>

<style lang="scss">
.send-contract-modal {
  width: 560px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 28px;

  &__link {
    color: #677A89;
    font-size: 14px;
  }

  & .is-active {
    box-shadow: 0 0 0 4px #ABE7FA;
  }

  &__select {
    position: relative;

    &-icon {
      position: absolute;
      top: 0;
      left: 12px;
      bottom: 0;
      margin: auto 0;
    }
  }

  & .app-modal__buttons {
    margin-top: 0;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  &__text {
    font-size: 16px;
    color: #677A89;
    text-align: center;
  }

  &__category {
    font-size: 18px;
    color: #1C262F;
    margin-bottom: 24px;
  }

  & .multiselect {
    border: 1px solid #9FAFBC;
    border-radius: 4px;
    --option-active: #0068AD;

    &-wrapper {
      margin-left: 25px;
    }

    &-dropdown {
      overflow-y: auto;
    }

    &-option {
      color: #677A89;

      &.is-pointed {
        background: var(--ms-group-label-bg-pointed, #D1D5DB);
        color: var(--ms-group-label-color-pointed, #374151);
      }

      &.is-selected {
        background: var(--option-active);
        color: white;
      }

      &.is-disabled {
        background: var(--ms-group-label-bg-disabled, #F3F4F6);
        color: var(--ms-group-label-color-disabled, #D1D5DB);
        cursor: not-allowed;
      }

      &.is-selected.is-pointed {
        background: var(--option-active);
        color: white;
      }

      &.is-selected.is-disabled {
        background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
        color: var(--ms-group-label-color-selected-disabled, #D1FAE5);
      }


      & span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
