<template>
  <div class="timeline-invitation" :style="{cursor: available ? 'pointer' : ''}" @click="onClick">
    <div class="timeline-invitation__body">
      <div class="timeline-invitation__avatar">
        <img v-show="image === 'invite'" src="@/assets/images/invites/invite.png" alt="" />
        <img v-show="image === 'company'" src="@/assets/images/invites/company.png" alt="" />
        <img v-show="image === 'user'" src="@/assets/images/invites/user.png" alt="" />
      </div>
      <span class="timeline-invitation__counter">{{ amount }}</span>
      <div class="timeline-invitation__info">
        <span class="timeline-invitation__category">{{ title }}</span>
        <span class="timeline-invitation__title">Pending {{ invitesString }}</span>
      </div>
      <div class="timeline-invitation__close">
        <CIcon name="cilX" @click.stop="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import { permissionError } from '@/services/Notify/Toasts'
export default {
  name: "TimelineInvitation",
  props: ['title', 'image', 'amount', 'available', 'permission', 'type'],
  computed: {
    invitesString() {
      return this.amount === 1 ? 'Invite' : 'Invites'
    }
  },
  methods: {
    onClick() {
      if (this.available) {

        if (this.permission) {

          if (this.type === 'company') {
            return this.$router.push({name: 'CompaniesInvited'})
          }

          if (this.type === 'user') {
            return this.$router.push({name: 'InvitedEmployees'})
          }

        } else {
          store.commit('setToast', permissionError)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.timeline-invitation {
  background: white;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 1px 4px 0 #00000033;
  border-left: 4px solid #0081C2;
  display: flex;
  margin-bottom: 15px;

  &__body {
    display: flex;
    align-items: center;
    padding: 14px;
    position: relative;
    width: 100%;
  }

  &__avatar {
    height: 36px;
    width: 36px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(95.17deg, #2BB0E0 -3.13%, #65DBC4 98.57%);
    flex-shrink: 0;

    & i {
      color: white;
    }
  }

  &__counter {
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    font-size: 44px;
    color: #1C262F;
    line-height: 1;
    margin: 0 7px 0 7px;
    transition: 0.5s;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__category {
    font-size: 12px;
    font-weight: 400;
    color: #1C262F;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #677A89;
    line-height: 1;
  }

  &__close {
    position: absolute;
    top: 3px;
    right: 5px;

    & svg {
      color: #9FAFBC;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
}
</style>
