<template>
  <div class="timeline-skeleton">

    <div class="timeline-skeleton__container">
      <span class="skeleton-box" style="width:80px;height: 16px;"></span>
      <span class="skeleton-box" style="width:72px;height: 12px;"></span>
    </div>

    <hr style="color: #24282b;">

    <div class="timeline-skeleton__container">
      <span class="skeleton-box" style="width:30%;height: 20px;background: #C3CDD5;"></span>
      <i style="color: #C3CDD5" class="fas fa-chevron-down"></i>
    </div>

    <span class="skeleton-box" style="width:70%;height: 12px;"></span>

    <div class="timeline-skeleton__container" style="margin-top: 10px;">
      <div>
        <span class="skeleton-box" style="width:12px;height: 12px;background: #C3CDD5;"></span>
        <span class="skeleton-box" style="width:72px;height: 16px;margin-left: 10px;"></span>
      </div>
      <span class="skeleton-box" style="width:80px;height: 12px;"></span>
    </div>

    <div class="timeline-skeleton__spacer"></div>

  </div>
</template>

<script>
export default {
  name: "TimelineSkeleton"
}
</script>

<style scoped lang="scss">
.timeline-skeleton {
  padding: 10px;

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  &__spacer {
    width: 100%;
    height: 16px;
    background: #F2F5F8;
  }
}
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DEE4E8;
  border-radius: 20px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
