<template>
    <div v-click-outside="close" class="range-calendar">
        <div class="range-calendar__header">
            <div class="range-calendar__toggle range-calendar__toggle_month">
                <i class="fas fa-chevron-left" @click="changeMonth(currentMonth - 1)"></i>
                <span @click="openedList = 'months'">{{ months[currentMonth] }}</span>
                <i class="fas fa-chevron-right" @click="changeMonth(currentMonth + 1)"></i>
            </div>

            <div class="range-calendar__toggle range-calendar__toggle_year">
                <i class="fas fa-chevron-left" @click="changeYear(currentYear - 1)"></i>
                <span>{{ currentYear }}</span>
                <i class="fas fa-chevron-right" @click="changeYear(currentYear + 1)"></i>
            </div>
        </div>

        <div v-if="!openedList" class="range-calendar__body">
            <ul class="weeks">
                <li v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek">{{ dayOfWeek }}</li>
            </ul>
            <ul class="days">
                <li
                    v-for="day in firstDayOfMonth"
                    :key="day"
                    class="inactive"
                    @click="onDaySelect(lastDateOfLastMonth - firstDayOfMonth + day, 'past')">
                    {{ lastDateOfLastMonth - firstDayOfMonth + day }}
                </li>
                <li
                    v-for="day in lastDateOfMonth"
                    :key="day"
                    :class="{
                        selected: isSelected(day),
                        finish: isFinishDate(day),
                        'selected-forward':
                            periodStartDate &&
                            periodEndDate &&
                            isSelected(day) &&
                            !isDateReversed() &&
                            !isFinishDate(day),
                        'selected-reversed': periodStartDate && periodEndDate && isSelected(day) && isDateReversed(),
                        'finish-reversed':
                            periodStartDate &&
                            periodEndDate &&
                            isFinishDate(day) &&
                            !isDateReversed() &&
                            !isSelected(day),
                        'finish-forward': periodStartDate && periodEndDate && isFinishDate(day) && isDateReversed(),
                        today: !periodStartDate && !periodEndDate && isToday(day),
                        ranged: isInRange(day),
                    }"
                    @mouseover="onDayHover(day)"
                    @click="onDaySelect(day)">
                    {{ day }}
                </li>
                <li v-for="day in 6 - lastDayOfMonth" :key="day" class="inactive" @click="onDaySelect(day, 'future')">
                    {{ day }}
                </li>
            </ul>
        </div>

        <div v-if="openedList === 'months'" class="range-calendar__list">
            <ul>
                <li
                    v-for="(month, i) in months"
                    :key="i"
                    :class="{ active: i === currentMonth }"
                    @click="changeMonth(i)">
                    {{ month.slice(0, 3) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import vClickOutside from 'click-outside-vue3';

export default {
    name: 'RangeCalendar',
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        selected: {
            type: Object,
        },
    },
    data() {
        return {
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            date: new Date(),
            currentMonth: null,
            currentYear: null,
            openedList: null,
            periodStartDate: null,
            periodEndDate: null,
            allowHovering: false,
        };
    },
    computed: {
        lastDateOfMonth() {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        firstDayOfMonth() {
            return new Date(this.currentYear, this.currentMonth, 1).getDay();
        },
        lastDateOfLastMonth() {
            return new Date(this.currentYear, this.currentMonth, 0).getDate();
        },
        lastDayOfMonth() {
            return new Date(this.currentYear, this.currentMonth, this.lastDateOfMonth).getDay();
        },
    },
    created() {
        this.currentMonth = this.date.getMonth();
        this.currentYear = this.date.getFullYear();

        if (this.selected.due_date_from && this.selected.due_date_to) {
            this.periodStartDate = this.selected.due_date_from;
            this.periodEndDate = this.selected.due_date_to;

            this.currentMonth = new Date(this.selected.due_date_from).getMonth();
            this.currentYear = new Date(this.selected.due_date_from).getFullYear();
        }
    },
    methods: {
        setPeriod() {
            if (this.periodEndDate && this.periodStartDate) {
                this.$emit('selected', {
                    due_date_from: this.isDateReversed() ? this.periodEndDate : this.periodStartDate,
                    due_date_to: this.isDateReversed() ? this.periodStartDate : this.periodEndDate,
                });

                this.close();
            }
        },
        isToday(day) {
            return moment(`${this.currentYear}-${this.currentMonth}-${day}`).isSame(
                moment(`${this.date.getFullYear()}-${this.date.getMonth()}-${this.date.getDate()}`),
            );
        },
        onDayHover(day) {
            if (this.periodStartDate && this.allowHovering) {
                this.periodEndDate = new Date(this.currentYear, this.currentMonth, day);
            }
        },
        onDaySelect(day, direction) {
            if (direction === 'future') {
                return false;
            }

            if (direction === 'past') {
                return false;
            }

            if (!this.periodStartDate && !this.periodEndDate) {
                this.periodStartDate = new Date(this.currentYear, this.currentMonth, day);
                this.allowHovering = true;
                return;
            }

            if (this.periodStartDate && this.periodEndDate && this.allowHovering) {
                this.allowHovering = false;
                this.setPeriod();
                return;
            }

            if (this.periodStartDate && this.periodEndDate && !this.allowHovering) {
                this.periodStartDate = new Date(this.currentYear, this.currentMonth, day);
                this.periodEndDate = null;
                this.allowHovering = true;
                return;
            }
        },
        isDateReversed() {
            if (this.periodStartDate && this.periodEndDate) {
                const startYear = this.periodStartDate.getFullYear();
                const startMonth = this.periodStartDate.getMonth();
                const startDate = this.periodStartDate.getDate();

                const finishYear = this.periodEndDate.getFullYear();
                const finishMonth = this.periodEndDate.getMonth();
                const periodEndDate = this.periodEndDate.getDate();

                const _startDate = moment(`${startYear}-${startMonth + 1}-${startDate}`);
                const _periodEndDate = moment(`${finishYear}-${finishMonth + 1}-${periodEndDate}`);

                return _startDate.isAfter(_periodEndDate);
            }
        },
        isInRange(day) {
            // Calculate dates that in the range
            if (this.periodStartDate && this.periodEndDate) {
                // If start and end dates present

                const startYear = this.periodStartDate.getFullYear();
                const startMonth = this.periodStartDate.getMonth();
                const startDate = this.periodStartDate.getDate();

                const finishYear = this.periodEndDate.getFullYear();
                const finishMonth = this.periodEndDate.getMonth();
                const periodEndDate = this.periodEndDate.getDate();

                const _startDate = moment(`${startYear}-${startMonth + 1}-${startDate}`);
                const _periodEndDate = moment(`${finishYear}-${finishMonth + 1}-${periodEndDate}`);

                const _givenDate = moment(`${this.currentYear}-${this.currentMonth + 1}-${day}`);

                return _periodEndDate.isAfter(_startDate)
                    ? _givenDate.isBetween(_startDate, _periodEndDate)
                    : _givenDate.isBetween(_periodEndDate, _startDate);
            }
        },
        isSelected(day) {
            if (this.periodStartDate) {
                if (this.periodStartDate.getFullYear() === this.currentYear) {
                    if (this.periodStartDate.getMonth() === this.currentMonth) {
                        if (this.periodStartDate.getDate() === day) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        isFinishDate(day) {
            if (this.periodEndDate) {
                if (this.periodEndDate.getFullYear() === this.currentYear) {
                    if (this.periodEndDate.getMonth() === this.currentMonth) {
                        if (this.periodEndDate.getDate() === day) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        changeMonth(month) {
            this.currentMonth = month;

            if (this.currentMonth < 0 || this.currentMonth > 11) {
                this.date = new Date(this.currentYear, this.currentMonth);
                this.currentYear = this.date.getFullYear();
                this.currentMonth = this.date.getMonth();
            } else {
                this.date = new Date();
            }

            this.openedList = false;
        },
        changeYear(year) {
            this.currentYear = year;
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.range-calendar {
    position: absolute;
    background: white;
    width: 375px;
    padding: 30px 15px;
    z-index: 100;
    box-shadow: 0px 4px 12px 2px #0000001f;
    border-radius: 24px;
    transform: translate(-133px, 15px);

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__toggle {
        display: flex;
        align-items: center;

        & i {
            font-size: 10px;
            color: #1c262f;
            cursor: pointer;
        }

        & span {
            margin: 0 15px;
        }

        &_month {
            & span {
                font-weight: 600;
                font-size: 16px;
                color: #1c262f;
                cursor: pointer;
            }
        }

        &_year {
            & span {
                font-weight: 600;
                font-size: 16px;
                color: #677a89;
            }
        }
    }

    &__body {
        & ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            flex-wrap: wrap;
            text-align: center;

            & li {
                width: calc(100% / 7);
                color: #1c262f;
            }

            & .inactive {
                color: #c3cdd5;
                font-weight: 400 !important;
            }
        }

        & .weeks {
            margin-top: 30px;
            color: #677a89;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;

            & li {
                color: #677a89;
            }
        }

        & .days {
            font-weight: 400;
            font-size: 14px;

            & li {
                margin-top: 10px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 30px;
                cursor: pointer;
                transition: 0.3s;
            }

            & .today {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    height: 5px;
                    width: 5px;
                    border-radius: 200px;
                    background: #0895d1;
                    top: 28px;
                }
            }

            & .selected {
                position: relative;
                color: #fff;

                &-forward {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 50%;
                        height: 100%;
                        background: #5ec7eb;
                        right: 0;
                        z-index: -2;
                    }
                }

                &-reversed {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 50%;
                        height: 100%;
                        background: #5ec7eb;
                        left: 0;
                        z-index: -2;
                    }
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 30px;
                    background: #0895d1;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 200px;
                    z-index: -1;
                }
            }

            & .range-complete {
                position: relative;
            }

            & .finish {
                position: relative;
                color: #fff;

                &-reversed {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 50%;
                        height: 100%;
                        background: #5ec7eb;
                        left: 0;
                        z-index: -2;
                    }
                }

                &-forward {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 50%;
                        height: 100%;
                        background: #5ec7eb;
                        right: 0;
                        z-index: -2;
                    }
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 30px;
                    background: #0895d1;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 200px;
                    z-index: -1;
                }
            }

            .ranged {
                background: #5ec7eb;
            }
        }
    }

    &__list {
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
        font-size: 14px;
        color: #1b1b1b;
        font-weight: 500;

        & ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            flex-wrap: wrap;
            margin-bottom: 0;
            width: 100%;

            & li {
                width: calc(100% / 3);
                text-align: center;
                margin-bottom: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 30px;
            }
        }

        & .active {
            background: #0895d1;
            border-radius: 24px;
            color: #fff;
        }
    }
}
</style>
