<template>
  <div class="panel-user">
    <div class="panel-user__avatar">
      <Avatar
        :display-name="[...user.initials.split('')]"
        :size="44"
        :rounded="true"
        :src="user.photo" />
    </div>
    <div class="panel-user__info">
      <h4>{{ user.name }}</h4>
      <p>{{ user.role_description }}</p>
      <span>{{ user.phone }} / {{ user.email }}</span>
    </div>
    <div v-if="canSelect" class="panel-user__select">
      <input :checked="selected" type="radio" id="radioButton" @click="onChange">
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: "PanelUser",
  components: {Avatar},
  props: ['user', 'canSelect'],
  data() {
    return {
      selected: false
    }
  },
  methods: {
    onChange() {
      this.selected = !this.selected
      if (this.selected) {
        this.$emit('onSelect', this.user)
      } else {
        this.$emit('onRemove', this.user)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-user {
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid #DEE4E8;
    width: 100%;

    &__info {
      padding: 0 10px;
      line-height: 1;
      flex: auto;

      & h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      & p {
        color: #677A89;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      & span {
        font-size: 14px;
        font-weight: 400;
        color: #677A89;
      }
    }

    &__select {
      display: flex;
      align-items: center;

      & input {
        height: 20px;
        width: 20px;
      }
    }
  }
</style>
