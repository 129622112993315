<template>
  <CModal
    content-class-name="new-scope-modal"
    size="lg"
    :visible="visible"
    backdrop="static"
    @close="closeModal"
  >
    <CModalHeader class="new-scope-modal__header">
      <CModalTitle class="new-scope-modal__title"
        >New Contract & Scope</CModalTitle
      >
    </CModalHeader>

    <CModalBody class="new-scope-modal__body">
      <h3 class="new-scope-modal__subtitle">Existing project</h3>
      <CSelectSearch
        v-model="search"
        class="new-scope-modal__search"
        label="Search by name or number"
        :options="projects"
        placeholder="Search by name or number"
        reverse
        :error="error"
        :search="true"
        :visible-not-found="false"
        :loading="loadingOptions"
        :view-all="true"
        @fetchOptions="fetchOptions"
      />

      <p class="new-scope-modal__text">
        Creating a new scope for a new project requires to create the project
        first. <br />Do you need to create a new project?
        <router-link
          :to="{
            name: 'ProjectNew',
          }"
          >Go to project ></router-link
        >
      </p>
    </CModalBody>

    <CModalFooter class="new-scope-modal__footer">
      <CButton
        color="primary"
        :disabled="disabledButton"
        @click="goToCreateScope"
        >Create scope</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script>
import CSelectSearch from '@/components/CSelectSearch'
export default {
  name: 'NewScopeModal',
  components: { CSelectSearch },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeProject: {
      type: Number,
      default: null,
    },
    loadingOptions: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['fetchOptions', 'selectedProjectId', 'close'],
  data() {
    return {
      search: +this.activeProject,
    }
  },
  watch: {
    search: {
      immediate: true,
      handler(id) {
        id && this.$emit('selectedProjectId', id)
      },
    },
  },
  methods: {
    fetchOptions(value) {
      this.$emit('fetchOptions', value)
    },
    goToCreateScope() {
      this.search &&
        this.$router.push({
          name: 'ProjectScopeNew',
          params: { id: this.search },
        })
    },
    closeModal() {
      const projectId = +this.activeProject
      this.search = projectId
      projectId && this.$emit('selectedProjectId', projectId)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.new-scope-modal {
  max-width: 560px;
  padding: 8px;

  &__header {
    position: relative;

    .btn-close {
      position: absolute;
      top: 21px;
      right: 4px;
    }
  }

  &__body {
    margin-top: 27px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.002em;
    color: #1c262f;
    margin-bottom: 24px;
  }

  &__text {
    margin-top: 52px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.003em;
    color: #677a89;
  }

  &__title {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;

    &::after {
      position: absolute;
      content: '';
      height: 2px;
      width: 120px;
      bottom: -20px;
      left: calc(50% - 60px);
      background-color: #0081c2;
    }
  }

  &__footer {
    margin-top: 23px;
    justify-content: center;
  }
}
</style>
