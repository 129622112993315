<template>
  <div :class="['dashboard-panel', { 'dashboard-panel--active': data }]">
    <div class="dashboard-panel__overlay" @click.self="close">
      <div class="dashboard-panel__body">
        <div v-if="data && chartData" class="panel">
          <div class="panel__heading">
            <div v-if="!searching" class="panel__name">
              <CIcon v-if="!clients_list" :name="chartIcon" />
              <CIcon style="cursor: pointer" v-else icon="cilArrowLeft" @click="clients_list = false" />
              <h4 class="panel__title">{{ clients_list ? 'Back' : data.chart.title }}</h4>
            </div>

            <div v-if="!searching && !clients_list" :class="['panel__actions', {'panel__actions_blocked': loading }]">
              <CIcon name="cilSync" @click="getData" data-action="sync" />
              <CIcon name="cilSearch" @click="searching = true" />
              <CIcon name="cilOptions" />
            </div>

            <div v-if="searching" class="panel__search">
              <div class="search-input">
                <CIcon name="cilSearch" />
                <input v-model="search_text" placeholder="Search" type="text" @input="onSearch" />
                <CIcon name="cilX" @click="closeSearching" />
              </div>
            </div>

            <button class="panel__close" @click="close">Close</button>

          </div>

          <div class="panel__content">

            <div
              v-if="!clients_list && chartData && handledSections.length"
              class="panel__events"
              @scroll="onScroll"
              ref="events">
              <DashboardStatusEvents
                v-for="section in handledSections"
                @load-more="loadMore(section)"
                :section="section"
                @open-clients-list="getEntityTeam" />
            </div>

            <div v-if="clients_list" class="panel__clients">
              <PanelUser
                v-for="user in clients_list.items.data"
                @on-select="selectedUsers.push($event.id)"
                @on-remove="onClientRemove"
                :can-select="canSelectMembers"
                :user="user" />
              <button v-if="canSelectMembers" :disabled="!selectedUsers.length" @click="onSend">
                <CIcon name="cilAddBell" />
                Send reminder
              </button>
            </div>
            <div v-if="handledSections.every(s => s.count === 0)" class="empty-events">
              <h5>We’re sorry. We were unable to dig out some results!</h5>
              <p>Please try to search with another query.</p>
              <img src="@/assets/images/no-results.png" alt="">
            </div>
            <div v-if="listOffset > 100" class="panel__elevator" @click="elevate">
              <CIcon name="cilArrowCircleTop" />
            </div>
          </div>
        </div>

        <div v-if="loading && !chartData" class="panel__loading">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import DashboardStatusEvents from "@/components/Dashboard/DashboardStatusEvents.vue";
import PanelUser from "@/components/Dashboard/PanelUser.vue";

export default {
  name: "DashboardPanel",
  components: {PanelUser, DashboardStatusEvents},
  props: ['data', 'params', 'due_date_from', 'due_date_to', 'canSelectMembers'],
  data() {
    return {
      searching: false,
      search_text: '',
      clients_list: false,
      chartData: null,
      loading: false,
      listOffset: 0,
      selectedUsers: []
    }
  },
  methods: {
    onClientRemove(user) {
      this.selectedUsers = this.selectedUsers.filter(id => user.id !== id)
    },
    onSend() {
      this.$router.push({
        name: 'SendCommunication',
        query: {
          users: this.selectedUsers
        }
      })
    },
    onScroll(e) {
      this.listOffset = e.target.scrollTop
    },
    elevate() {
      if (this.$refs.events) {
        this.$refs.events.scrollTop = 0
      }
    },
    loadMore(section) {
      this.getData({ section: section.slug, per_page: section.items.perPage + 4 })
    },
    getEntityTeam(id) {
      this.$http.dashboard.getChartEntityTeam({ category: this.data.section, type: this.data.chart.slug, entity_id: id })
        .then(res => this.clients_list = res.data.data)
    },
    onSearch() {
      this.getData()
    },
    close() {
      this.searching = false
      this.search_text = ''
      this.clients_list = false
      this.$emit('close')
    },
    closeSearching() {
      this.search_text = ''
      this.searching = false
      this.getData()
    },
    getData(additionalParams) {
      this.loading = true
      let params = {
        category: this.data.section,
        type: this.data.chart.slug
      }

      if (this.search_text) {
        params.search = this.search_text
      }

      if (additionalParams) {
        params = Object.assign({}, params, additionalParams)
      }

      if (this.due_date_from && this.due_date_to) {
        params.due_date_from = this.due_date_from
        params.due_date_to = this.due_date_to
      } else {
        params = Object.assign({}, params, this.params)
      }

      this.$http.dashboard.getChartDetails(params)
        .then(res => {
          const data = res.data.data

          if (Array.isArray(data.sections)) {
            this.chartData = res.data.data
          } else {
            for (let status in data.sections) {

              this.chartData.sections.forEach((section, i) => {
                if (status === section.slug) {
                  this.chartData.sections[i] = data.sections[status]
                }
              })
            }
          }
        })
        .finally(() => this.loading = false)
    }
  },
  computed: {
    chartIcon() {
      const icons = {
        'scope': 'cilPen',
        'contract': 'cilSignature',
        'task': 'cilCalendar'
      }

      return this.data && this.data.chart ? icons[this.data.chart.slug] : ''
    },
    handledSections() {
      return this.chartData.sections.filter(section => section.count > 0)
    }
  },
  watch: {
    data(val) {
      if (val) {
        document.getElementsByTagName('body')[0].classList.add('body-blocked')
        this.getData()
      } else {
        document.getElementsByTagName('body')[0].classList.remove('body-blocked')
        this.chartData = null
      }
    }
  },
  unmounted() {
    document.getElementsByTagName('body')[0].classList.remove('body-blocked')
  }
}
</script>

<style lang="scss">
.body-blocked {
  overflow: hidden;
}
.dashboard-panel {

  &--active {
    & .dashboard-panel__overlay {
      opacity: 1;
      z-index: 100000;
      visibility: visible;
    }
    & .dashboard-panel__body {
      transform: translateX(0);
    }
  }
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 0;
    background-color: #0000006e;
    transition: 0.4s;
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
  }
  &__body {
    background-color: #fff;
    width: 536px;
    height: 100%;
    transform: translateX(100%);
    transition: 0.3s;
  }
  & .panel {
    height: 100%;

    &__loading {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      height: 100%;
      position: relative;
    }

    &__elevator {
      position: fixed;
      right: 25px;
      bottom: 25px;
      height: 36px;
      width: 36px;
      border-radius: 200px;
      background-color: rgba(0, 0, 0, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.70);
      }

      & svg {
        height: 80% !important;
        width: 80% !important;
        color: #fff;
      }
    }

    &__heading {
      background-color: #202E39;
      height: 48px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 600;
    }

    &__name {
      flex: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      & h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        font-family: Montserrat, sans-serif;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 20px;

      & svg {
        color: white;
        cursor: pointer;
      }



      &_blocked {
        pointer-events: none;

        & svg {
          color: rgba(239, 239, 239, 0.43) !important;
        }

        & [data-action="sync"] {
          & path {
            fill: rgba(239, 239, 239, 0.43);
          }
        }
      }
    }

    &__search {
      width: 100%;
    }

    &__close {
      height: 32px;
      border-radius: 8px;
      padding: 0px 20px;
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;

      &:hover {
        background-color: #fff;
        color: black;
      }
    }

    &__events {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 80px;

      & .status-events:nth-child(even) {
        background-color: #F2F5F8;
      }
    }

    &__clients {
      padding: 16px;

      & button {
        background-color: #FF1F26;
        border-radius: 8px;
        height: 40px;
        display: block;
        padding: 8px 24px;
        color: #fff;
        border: none;
        outline: none;
        margin: 25px auto 0 auto;

        &:disabled {
          background-color: #FF6B72;
        }
      }
    }
  }
  & .search-input {
    display: flex;
    gap: 5px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #677A89;
    padding: 0 10px;
    min-height: 36px;

    &:hover {
      background-color: #32424E;
    }

    & input {
      background-color: transparent;
      border: none;
      outline: none;
      padding-left: 5px;
      color: white;
      width: 100%;

      &:focus {

        &::placeholder {
          color: #DEE4E8;
        }
      }
    }
  }
  & .empty-events {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    & h4 {
      font-size: 14px;
      font-weight: 600;
      color: #677A89;
    }

    & p {
      font-size: 12px;
      color: #677A89;
      font-weight: 400;
    }

    & img {
      margin-top: 20px;
      width: 200px;
    }
  }
}
</style>
