<template>
  <div class="timeline-notification" :class="notificationStyle">
    <div class="timeline-notification__card">
      <Avatar :src="notification.logo" :display-name="initials" size="40" />

      <div class="timeline-notification__info">
        <div class="timeline-notification__heading">
          <CIcon v-if="overdueType" icon="cilWarningFilled" />
          <strong>{{ notification.title }}</strong>
          <span>{{ date }}</span>
        </div>

        <div v-if="!inviteType" class="timeline-notification__action">
          <span v-if="notification.action_text" style="margin-right: 5px;">
            {{ notification.action_text }}
          </span>
          <router-link
            v-if="notification.action_url && notification.action_text_url"
            :to="notification.action_url">
              {{ notification.action_text_url }}
          </router-link>
        </div>

        <span v-if="inviteType" class="timeline-notification__status">
          <i class="fas" :class="inviteTypeIcon"></i>
          {{ notification.action_text }}
        </span>

      </div>
    </div>

    <div v-if="footerInfo" class="timeline-notification__footer">
      <p class="timeline-notification__number">
        {{ notification.project_number }}
        <span class="timeline-notification__project"> - {{ notification.project_name }}</span>
      </p>

      <div class="timeline-notification__bottom">
        <span
          v-if="notification.company_name"
          class="timeline-notification__project text-ellipsis">
          {{ notification.company_name }}
        </span>

        <div v-if="discussionType" style="display: flex;align-items: center;gap: 5px;padding-left: 5px;">
          <i class="fas" :class="discussionIcon"></i>
          <strong>{{ discussionType === 'approved' ? 'Approved' : 'Rejected' }}</strong>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";
import moment from "moment";

export default {
  name: "TimelineNotification",
  props: ['notification'],
  components: {Avatar},
  computed: {
    discussionIcon() {
      return this.notification.type.includes('approved') ? ['fa-check-circle'] : ['fa-times-circle']
    },
    notificationStyle() {
      let styles = {
        'timeline-notification--success': this.notification.type === 'contract_discussion_was_approved',
        'timeline-notification--danger': this.notification.type === 'contract_discussion_was_rejected' || this.overdueType,
      }

      return styles
    },
    initials() {
      // const type = this.notification.type
      // const typesWithNoUserInitials = ['contract_is_overdue', 'request_is_overdue', 'acknowledge_is_overdue']
      //
      // let nameForInitials = this.notification.title
      //
      // if (typesWithNoUserInitials.includes(type)) {
      //   nameForInitials = this.notification.action_text_url
      // }
      //
      // return [nameForInitials]

      return this.notification.initials.split('');
    },
    date() {
      const date = this.notification.created_at
      const format = moment.utc(date).local().isSame(moment().local(), 'day') ? 'hh:mm A' : 'MM/DD'

      return moment.utc(date).local().format(format)
    },
    footerInfo() {
      return this.notification.project_name && this.notification.project_number
    },
    overdueType() {
      const inviteTypes = [
        'contract_is_overdue',
        'request_is_overdue',
        'acknowledge_is_overdue'
      ]

      return inviteTypes.includes(this.notification.type)
    },
    discussionType() {
      const discussionTypes = [
        'contract_discussion_was_approved',
        'contract_discussion_was_rejected'
      ]

      if (discussionTypes.includes(this.notification.type)) {
        return this.notification.type === 'contract_discussion_was_approved' ? 'approved' : 'rejected'
      }

      return false
    },
    inviteType() {
      const inviteTypes = [
        'employee_accepted_invite',
        'employee_declined_invite',
        'company_accepted_invite',
        'company_declined_invite'
      ]

      return inviteTypes.includes(this.notification.type)
    },
    inviteTypeIcon() {
      return this.notification.type.includes('accepted') ? ['fa-check-circle'] : ['fa-times-circle']
    }
  }
}
</script>

<style lang="scss">
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.timeline-notification {
  border-bottom: 4px solid #DEE4E8;

  &--success {
    background: #9FE9DA40 !important;
  }

  &--danger {
    background: #FF1F261A !important;
  }

  &__card {
    display: flex;
    padding: 12px 8px;
    gap: 8px;
  }

  &__info {
    width: 100%;
  }

  &__heading {
    display: flex;
    align-items: center;

    & strong {
      font-weight: 600;
      font-size: 14px;
      width: 125px;

      @extend .text-ellipsis;
    }

    & span {
      font-weight: 400;
      font-size: 12px;
      margin-left: auto;
      flex-shrink: 0;
    }

    & svg {
      margin-right: 5px;

      & path {
        fill: red;
      }
    }
  }

  &__action {
    font-weight: 700;
    font-size: 12px;
    width: 160px;
    display: flex;

    & span {
      flex-shrink: 0;
    }

    & a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 190px;
      color: #1C262F !important;
    }
  }

  &__link {
    flex: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__status {
    font-weight: 400;
    font-size: 14px;
    color: #1C262F;

    & .fa-check-circle {
      color: #0BBD99;
    }

    & .fa-times-circle {
      color: #FF1F26;
    }
  }

  &__footer {
    border-top: 1px solid #DEE4E8;
    padding: 12px 8px;
  }

  &__number {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    width: 100%;

    @extend .text-ellipsis;
  }

  &__project {
    font-weight: 400;
    font-size: 12px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .fa-check-circle {
      color: #0BBD99;
    }

    & .fa-times-circle {
      color: #FF1F26;
    }

    & strong {
      font-weight: 700;
      font-size: 12px;
    }

    & i {
      font-size: 13px;
    }
  }
}
</style>
