<template>
    <div class="status-events">
      <div class="category-status">
        <div class="category-status__line"></div>
        <div class="category-status__label">
          <h5>{{ section.title }} ({{ section.count }}) -</h5>
          <span :class="{ 'overdue-string': section.overdue_count }">
            <CIcon name="cilWarningFilled" />
            Overdue ({{ section.overdue_count }})
        </span>
        </div>
      </div>

      <div
        v-for="item in section.items.data"
        class="category-item"
        :style="{'border-left': `4px solid ${ item.is_overdue ? 'red': '#0BBD99' }`}">
        <div class="category-item__heading">
          <div class="category-item__name">
            <CIcon v-if="item.is_overdue" name="cilWarningFilled" />
            <router-link :to="item.link">
              {{ item.name }}
            </router-link>
          </div>
          <div class="category-item__info">
            <span v-if="item.amount">{{ item.amount }}</span>
            <CIcon
              v-if="item.amount"
              class="category-item__budgetIcon" name="cilArrowCircleTop"
              :style="{ color: item.amount_color, transform: item.amount_color === 'red' ? 'rotate(180deg)' : '' }" />
            <strong :style="{ 'border-left': item.amount ? '' : 'none' }">{{ getDate(item.due_date) }}</strong>
            <img src="@/assets/clients.svg" @click="$emit('openClientsList', item.id)" />
          </div>
        </div>
        <div class="category-item__stats">
          <span>{{ item.project_number }}</span>
          <span>{{ item.project_name }}</span>
          <span>|</span>
          <span>{{ item.external_company_name }}</span>
        </div>
      </div>

      <p
        @click="loadMore"
        :class="['status-events__load', {'status-events__load_active': section.items.total > section.items.data.length }]">
        Load more
      </p>

    </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardStatusEvents",
  props: ['section'],
  methods: {
    getDate(date) {
      return moment(date).format('MM/DD/YY')
    },
    loadMore() {
      if (this.section.items.total > this.section.items.data.length) {
        this.$emit('loadMore')
      }
    }
  }
}
</script>

<style lang="scss">
  .status-events {
    padding: 15px;

    &__load {
      text-align: center;
      color: #C3CDD5;
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
      margin: 20px 0 0 0;
      font-family: "Segoe UI", sans-serif;

      &_active {
        color: #0068AD;
        cursor: pointer;
      }
    }

    & .category-status {
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 16px;

      & span {
        color: #677A89;
      }

      &__line {
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: #9FAFBC;
        top: 0;
        bottom: 0;
        margin: auto 0;
        z-index: 0;
      }

      &__label {
        background-color: #000000;
        padding: 4px 16px 5px 16px;
        border-radius: 48px;
        color: #fff;
        z-index: 1;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;

        & .overdue-string {
          color: white;
          display: flex;
          align-items: center;
          gap: 5px;

          & path {
            fill: red !important;
          }
        }

        & h5 {
          margin: 0;
          font-size: 14px;
        }

        & span {
          margin-left: 5px;
        }
      }
    }

    & .category-item {
      min-height: 68px;
      border-top: 1px solid #C3CDD5;
      border-right: 1px solid #C3CDD5;
      border-bottom: 1px solid #C3CDD5;
      padding: 8px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 8px;

      &:hover {
        background: #dbdbdb;
      }

      &__names {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        & h4 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &__number {
        width: 73px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 0;
      }

      &__info {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;

        & span {
          font-weight: 600;
          text-transform: lowercase;
          color: #1C262F;
          font-family: "Segoe UI", sans-serif;
        }

        & strong {
          font-weight: 700;
          font-size: 12px;
          border-left: 1px solid grey;
          border-right: 1px solid grey;
          padding: 0 8px;
          font-family: "Segoe UI", sans-serif;
        }

        & img {
          cursor: pointer;
        }
      }

      &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #1C262F;
      }

      &__name {
        font-size: 16px;
        color: #1C262F;
        text-decoration: underline;
        font-family: "Segoe UI", sans-serif;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        & a {
          color: #1C262F !important;
        }

        & svg {
          transform: translateY(1px);

          & path {
            fill: red;
          }
        }
      }

      &__stats {
        display: flex;
        align-items: center;
        gap: 8px;

        & span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        & span:first-child {
          max-width: 80px;
          color: #1C262F;
          font-size: 12px;
          font-weight: 600;
        }

        & span:nth-child(2) {
          max-width: 172px;
          font-size: 12px;
          font-weight: 600;
          color: #677A89;
        }

        & span:nth-child(3) {
          font-size: 12px;
          color: #677A89;
        }

        & span:nth-child(4) {
          max-width: 136px;
          font-size: 12px;
          font-weight: 600;
          color: #677A89;
        }

        //& strong, & h4 {
        //  font-size: 12px;
        //  font-weight: 600;
        //  margin-bottom: 0;
        //}
        //
        //& strong {
        //  color: #1C262F;
        //}
        //
        //& h4 {
        //  color: #677A89;
        //}
      }
    }
  }
</style>
